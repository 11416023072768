import React from 'react'
import {Link} from 'gatsby'
import Logo from '../images/logo.svg'

const Header =()=>{
    return(
        <div className="Header">
            <div className="container">
                <nav className="navbar navbar-expand-lg">
                    <Link to="/" className="navbar-brand"><img src={Logo} alt="Logo"/></Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                        <ul className="navbar-nav">
                            <li className="nav-item"><Link to="/about">About</Link></li>
                            <li className="nav-item"><Link to="/blog">Blog</Link></li>
                            <li className="nav-item"><Link to="/contact">Contact</Link></li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    );
}

export default Header
